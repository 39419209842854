import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const LimbusIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_intro.jpg"
            alt="Introduction to the game"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>
            A summary of the information available before the release of Limbus
            Company.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          The information available in the guide was compiled by Azure#6456.
          Huge thanks!
        </p>
        <SectionHeader title="Identities and EGO" />
        <StaticImage
          src="../../../images/limbuscompany/generic/limbus_characters_sm.jpg"
          alt="Guide"
        />
        <p>Identities and EGO are the main gacha elements in Limbus Company.</p>
        <p>
          <strong>Identities (IDs)</strong> represent alternate universe
          versions of the twelve Sinners, showcasing different forms of
          themselves with unique abilities, skillsets, strengths and weaknesses.
          As all twelve Sinners in Limbus Company are unlocked for the player by
          default, these serve as completely different units applied to the
          specific Sinner they belong to, and cannot be equipped on any other
          Sinner. Different Sinners may have Identities belonging to the same
          group, but these are not interchangeable.
        </p>
        <p>
          <strong>EGO</strong> are powerful special attacks that can be equipped
          onto any identity of the Sinner they belong, and are activated by
          consuming in-battle resources to unleash powerful attacks with special
          effects and buff your resistances. Each Sinner can have up to five
          EGOs equipped at once, one for each of the five tiers available:
          ZAYIN, TETH, HE, WAW and ALEPH. EGO higher on the list will have more
          powerful effects, but also be significantly more costly and, at times,
          riskier to use. As with Identities, EGOs can only be equipped on the
          specific Sinner they belong to. Even though several Sinners may have
          EGO from the same Abnormality, these are not interchangeable.
        </p>
        <SectionHeader title="Upgrades and Promotion" />
        <StaticImage
          src="../../../images/limbuscompany/gacha/image1.jpg"
          alt="Gacha guide"
        />
        <p>
          Identities and EGO can be upgraded and promoted in order to increase
          their overall stats, gain new abilities and passives and, at times,
          even gain new artwork. Identities and EGO follow separate and
          individual upgrade paths.
        </p>
        <p>
          Identities can be upgraded through leveling up via combat or by using
          Experience tickets obtained in game, and promoted via the Uptying
          system from Uptie 1 to 3, unlocking new Skills with each promotion and
          alternative art upon reaching the maximum Uptie level. Uptying is
          performed via the consumption of Threads, a resource explained later
          on.
        </p>
        <p>
          EGO, unlike Identities, don't have any sort of levelling system, but
          share a similar promotion system dubbed Threadspinning. EGO can be
          promoted via the Threadspinning system from Threadspin 1 to 3,
          unlocking new abilities and boosting their power with each promotion.
          Threadspinning is performed via the consumption of Threads as well.
        </p>
        <SectionHeader title="Gacha, Pity and Monetization" />
        <StaticImage
          src="../../../images/limbuscompany/generic/guide_gacha.jpg"
          alt="Gacha guide"
        />
        <p>
          The Gacha in Limbus Company features multiple banners with different
          focus on each, so players may obtain the desired Identities and EGO by
          spending Lunacy, the summoning currency of this game. Identities and
          EGO share the same gacha pool, meaning a single pull may net you
          either of the two.
        </p>
        <p>
          Unlike most other gacha games, Limbus Company features no Duplicate
          system, meaning pulling multiple copies of a single Identity will not
          result in an increased powerlevel. Instead, all duplicate Identities
          immediately transform into Shards of the respective Sinner, which can
          be used to directly purchase Identities or EGO from a shop.
        </p>
        <p>
          Each EGO, on the other hand, removes itself from the pool immediately
          upon acquisition, making it imposible to obtain duplicate versions of
          each EGO and thus increasing the chance for Identities or unobtained
          EGO.
        </p>
        <p>
          The main monetization system is the Battle Pass, known as Limbus Pass
          in the game which has both a free and a premium section. Once certain
          tasks are completed, the Pass will earn experience and leveling up,
          granting the player the specified resources on the free version, along
          with the premium version if it is purchased. The Pass contains special
          EGO in the free version for all players to claim, plus additional EGO
          temporarily exclusive to the premium version. EGO present in the
          premium version may be crafted via Shards on the second season after
          it first releases, with each season lasting for 3 months. Additional
          premium Limbus Pass rewards include more Shards, Lunacy, and exclusive
          cosmetic Announcers in the form of different characters to commentate
          your battles. The price of the premium Limbus Pass is as of the
          writing of this text unknown.
        </p>
        <p>
          Additionally, players may also purchase monthly passes for Lunacy (for
          3 or 7 dollars depending on the specific pass) or directly buy Lunacy
          in bulk.
        </p>
        <SectionHeader title="Combat and Coins" />
        <StaticImage
          src="../../../images/limbuscompany/generic/combat.jpg"
          alt="Gacha guide"
        />
        <p>
          In Limbus Company, combat is performed by chaining together Skills for
          each Sinner present in battle to clash against the enemies. Only five
          Sinners may actively participate in combat, with the remaining seven
          providing passive buffs from the backrow.
        </p>
        <p>
          During regular battles, each Sinner will target an enemy
          automatically, dictating a clash where the more powerful attack goes
          through, completely negating the other if the dice roll is higher.
          These attacks may be powered up by stringing multiple of the same
          attribute (Sin) together, resulting in Resonance or Absolute Resonance
          for a significant power boost.
        </p>
        <p>
          Each time an attack is performed, the counter for the specific Sin
          type of the attack will increase. These Sins are used as resources to
          activate passives, power up effects, and consumed to activate powerful
          EGO attacks.{' '}
        </p>
        <p>
          These EGO attacks will also take away some of the Sinners' Sanity,
          resulting in extremely powerful but uncontrollable effects (known as
          EGO Corrosion) if their Sanity dips too low. Sanity is constantly
          increased and decreased through battle by winning or losing against
          enemies.
        </p>
        <p>
          Each attack Skill consists of one or more rolls, each with a separate
          "Coin Bonus". When a Skill is activated against an enemy, a coin will
          be flipped for each of their attacks, grating powerful buffs or even
          negative effects depending on whether it lands on heads on tails.
          These coin flips are influenced by the Sanity of your Sinners, with
          higher Sanity correlating to better success rates in coin flips.
        </p>
        <p>
          During a special type of combat encounter against Abnormalities, the
          player will be able to control each Sinner's targeting priority,
          deciding against which body part of the Abnormality they will clash
          with. Each part may perform different attacks, and have a separate
          health bar that temporarily disables the part when depleted, for
          better or worse. Fights against these entities may also introduce
          special, story-oriented decisions mid-battle, risking a Sinner's
          wellbeing for a positive or negative influence in battle.
        </p>
        <p>Check the combat in action here:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="qG8IpYtnia4" />
          </Col>
        </Row>
        <SectionHeader title="Mirror Dungeons" />
        <p>
          Mirror Dungeons (MDs) are an alternate gamemode that players can
          regularly challenge to obtain a multitude of resources, including but
          not limited to Shards, Threads, Battle Pass progress and Experience.
        </p>
        <p>
          MDs are procedurally generated dungeons where your team of sinners
          must navigate through multiple nodes encountering combat scenarios and
          different events that may provide some boons for the run. At the
          beginning of an MD, only a team of three Sinners may be enlisted, with
          additional ones unlocking further into the MD. These Sinners
          additionally have their levels capped up to a maximum, which may be
          increased after combat and through events to power up your Sinners up
          to their original values.
        </p>
        <p>
          MDs can be challenged at any time, but rewards will only be provided
          if specific conditions are met when entering. MDs will provide rewards
          for no cost once a day, and additional reward runs may be performed by
          spending different resources obtained elsewhere (Enkephalin).
        </p>
        <p>Check the mode in action here:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="y-oWMYctuxU" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Limbus Company | Prydwen Institute"
    description="A summary of the information available before the release of Limbus
    Company."
  />
);
